import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div style={{ margin: '10px' }}>
      <h1>问旅用户隐私政策</h1>
      <p>生效日期：2024年7月19日</p>
      <p>更新日期：2024年7月19日</p>
      <h2>引言</h2>
      <p>问旅（以下简称为“我们”）尊重并保护所有问旅应用程序用户的个人信息及隐私安全。为同时给您提供更准确、有个性化的服务和更安全的互联网环境，我们依据《中华人民共和国网络安全法》《中华人民共和国个人信息保护法》《中华人民共和国民法典》《信息安全技术个人信息安全规范》以及其他相关法律法规和技术规范明确了我们收集/使用/对外提供个人信息的原则，进一步阐述了关于您个人信息的相关权利。</p>
      <p>本政策与您所使用的我们的产品与/或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与/或服务（以下统称“使用我们的产品与/或服务”）时，我们将按照本政策的约定处理和保护您的个人信息。我们尽量以简明扼要的表述向您解释本政策所涉及的技术词汇，以便于您理解。<b>本政策中与您权益（可能）存在重大关系的条款，我们已使用加粗字体予以区别，请您重点查阅。</b></p>
      <p><b>问旅应用程序特别提请未满18周岁的未成年（特别是未满十四周岁的儿童）及其监护人的注意，我们已明确未成年保护专门章节（以粗体提示），请您仔细阅读。</b></p>
      <p>请在使用/继续使用我们的各项产品与服务前，仔细阅读并充分理解本政策，并在需要时，按照本政策的指引，做出适当的选择。如果您不同意本政策的内容，将可能导致我们的产品与/或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用我们提供的产品与/或服务的行为，都表示您充分理解和同意本《问旅用户隐私政策》（包括更新版本）的全部内容。</p>
      <h2>关于我们</h2>
      <p>问旅应用程序的经营者为广州浩旅河山信息科技有限公司及其关联公司。</p>
      <p>注册地址为广州市天河区中山大道西6号、8号夹层自编J-A26房号。</p>

      <h2>目录</h2>
      <p><b>一、我们如何收集和使用您的个人信息</b></p>
      <p><b>二、我们如何共享、转让、公开披露您的个人信息</b></p>
      <p><b>三、我们如何存储和保护您的个人信息</b></p>
      <p><b>四、有关第三方提供产品和/或服务的特别说明</b></p>
      <p><b>五、我们如何使用Cookie和其他同类技术</b></p>
      <p><b>六、未成年人保护</b></p>
      <p><b>七、我们如何更新隐私政策</b></p>
      <p><b>八、如何联系我们</b></p>
      <p><b>九、其他</b></p>

      <h2>一、我们如何收集和使用您的个人信息</h2>
      <p><b>您理解并同意：</b></p>
      <p>问旅是旅游攻略和的旅游生活分享的应用程序，用户可以通过短视频、图文等形式记录和分享旅游生活点滴，通过互帮互助的方式帮助他人有更好的旅行体验。我们将在本政策中依次向您说明每项功能可能收集的个人信息范围、收集目的、收集方式，以及拒绝提供个人信息可能导致的结果。请您知悉，您拒绝提供各项功能实现所必要的对应信息时，仍然可以使用问旅App的其他功能。</p>
      <p>我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用我们服务过程中主动提供或因使用我们产品和/或服务而产生的个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将已经收集的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。您可通过【我-设置-个人信息收集清单】查看详情。</p>
      <p><b>1.账号注册及登录</b></p>
      <p>1.1.当您注册及登录时，如您使用一键登录的功能，基于我们与通信运营商的合作，我们会收集您的<b>【手机号码和网络运营商信息】</b>，以便为您提供快捷的登录服务；同时，为帮助您完成注册过程，我们还将收集您的<b>【性别】、【兴趣】和【年龄】</b>，以为您提供问旅的初始服务。如不希望提供，您可以<b>跳过此步骤</b>。</p>
      <p>1.2.<b>如您使用第三方账号进行登录，为了满足网络实名制要求，在您使用发布、评论及其他要求实名认证的功能与服务前，我们将另行收集您的手机号码以完成实名认证。</b>请您谨慎考虑后提供这类信息，若您拒绝提供，您可能无法正常使用相应的功能。</p>
      <p>1.3. 您还可以根据自身需求选择填写或更改头像、名字、性别、生日、职业、地区、学校、简介、穿搭信息来完善您的信息，但如您不提供此类信息，不会影响您使用本产品和相关服务。</p>
      <p>1.4. 如果您成功注册问旅账号，我们会在问旅应用程序及网站中显示您的名字、头像、地区、简介信息。</p>
      <p><b>2.向您提供产品和/或服务</b></p>
      <p><b>2.1.信息浏览、发布、点赞、收藏、分享功能</b></p>
      <p>2.1.1.为了向您提供我们最核心的信息展示服务并向您推荐可能感兴趣的笔记及相关信息，我们会收集<b>您填写个人资料、您的性别、生日、浏览记录（您的关注、浏览）和点击操作记录（兴趣、点击、搜索、点赞、收藏、分享、评论、发布记录及有关行为）。若您希望关闭我们基于有关信息向您进行的推荐，您可参照本政策2.14条进行操作。</b></p>
      <p>2.1.2.当您使用发布、编辑内容功能时，我们会请求您授权存储（相册、媒体和其他文件）、相机（摄像头）、麦克风（录音）权限。您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用问旅的其他功能。</p>
      <p>在您发布笔记、直播等互动内容时，我们会使用您授权的地理位置信息判断您发布内容时所在的地理位置，并在后续使用该信息将您发布的内容推荐给其他用户。</p>
      <p>在您同意的情况下，我们将根据您开启直播时的位置信息，将您直播间链接等直播信息在附近功能的地图界面展示给用户，方便与您在同一地理区域的用户发现和访问您的直播间。</p>
      <p>2.1.3.当您使用发布、评论、点赞、收藏、分享功能时，<b>您发布的文字、照片、视频、音频、评论、点赞、收藏、分享记录信息会存储在我们的服务器中</b>，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。</p>
      <p>2.1.4 如您拍摄或者编辑内容时使用地理位置贴纸，经您授权，<b>我们会使用您的地理位置信息以生成贴纸</b>。贴纸将在内容中公开展示，如您拒绝地理位置授权，将无法使用该功能，但不影响您使用其他功能。<b></b>我们也会使用您授权的地理位置信息，在作品发布页面为你推荐可选用的地理位置链接。<b>我们也会在推荐地理位置锚点时使用该图片/视频自带的媒体信息中的位置信息</b>，您可以通过本隐私政策第四条第三款控制该信息的获取。</p>
      <p>2.2.安全运行与风控验证。为了保障软件与服务的安全运行，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们会主动收集您的设备信息【包括：设备/硬件型号、设备名称、SIM卡序列号（如ICCID）、硬件/设备序列号（SN）、设备唯一标识符（包括：IMEI、IMSI、Android ID、IDFA、IDFV、OAID、MEID）】、运行中的进程、存储（相册、媒体和其他文件）、浏览器类型和设置、语言设置、传感器、操作系统、操作系统版本号和应用程序版本、应用列表、网络设备硬件地址（MAC地址）、WLAN-MAC、WLAN接入点（如SSID，BSSID）、IP地址、蓝牙地址、接入网络的方式、网络质量数据、基站、移动网络信息（包括运营商名称）、设备传感器数据（如加速度传感器、线性加速度传感器、重力传感器、陀螺仪传感器）、软件版本号以及软件使用记录信息上传到服务器处理，用于安全风控验证。如您不同意我们收集前述信息，可能无法完成风控验证。请您理解，为动态检测您的账户是否存在异常登录，每次您把问旅切换至后台或重新启动程序时，我们可能会再次读取，我们将把读取频次控制在合理范围内。为了维护网络安全、保障良好生态，您的IP地址所对应的归属地域信息将会展示在您的个人资料页、笔记发布页和评论中。境内展示到省(区、市)，境外展示到国家(地区)，信息以网络运营商数据为准。</p>
      <p>2.3.搜索功能。当您使用问旅搜索功能时，我们会收集分析<b>您的搜索指令（可能是文本或者图像形式）、阅读记录和访问时间、评论和互动记录。</b>收集此信息可以向您提供您所需要的内容和可能更感兴趣的服务，同时亦可以改进我们的产品和服务。</p>
      <p>2.4.基于地理位置的功能服务 。当您通过系统授权开启移动设备的定位功能并使用问旅基于地理位置提供的相关服务（包括基于地理位置的内容推荐、附近功能以及附近功能地图界面上本地生活信息和互动内容的展示、内容发布时的位置定位、购物编辑收货信息和资料编辑）时，我们会收集和使用您的位置信息以便为您提供上述服务。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但不影响您正常使用问旅的其他功能。我们也仅会基于网络信息（如IP）、或经过你授权的相应地理位置信息对应的城市及/或区县及/或街道向您展示可能相关的内容或服务。</p>
      <p>2.5.互动功能与服务。</p>
      <p>2.5.1.当您关注您感兴趣的账号、进行浏览、评论、点赞、收藏、分享内容时，我们会收集<b>您关注的账号和前述信息</b>，并向您展示您关注账号发布的内容；同时，您点赞或收藏的内容会向您的粉丝展示，您可将收藏的内容设置仅自己可见，可以在<b>【我】-【收藏】</b>中开启仅自己可见。</p>
      <p>2.5.2.当您购买商品或服务时，如您需要编辑收货信息，<b>我们可能会向您请求通讯录权限</b>，通讯录权限是敏感权限，拒绝提供仅会使您无法通过通讯录编辑收货信息，但不影响其他功能的使用。</p>
      <p>2.5.3. 当您使用群聊功能进行互动交流时，我们会收集与群及群成员相关的信息，并形成群列表。</p>
      <p>2.6.客服功能。当您联系我们的客服时，我们系统可能会记录您与客服之间的<b>通讯记录</b>。为了您的账号与系统安全，我们可能需要您提供相关个人信息与您之前提供的个人信息相匹配以验证您的用户身份。验证成功后，我们可能会收集您与我们的沟通信息（账号信息、您为了证明相关事实而提供的图片/视频/文字信息及有关信息）、您的联系方式以及您与我们的<b>沟通记录</b>，包括<b>线上沟通记录、电话录音</b>，以便尽快为您解决问题和改进我们的产品与/或服务。同时，我们可能会以电话、短信、站内信及相关方式主动联系您，沟通您在使用我们产品及服务过程中产生的问题。</p>
      <p>2.7.为保证您在使用问旅应用程序服务时能够与后台服务器保持即时通信、且您能够及时收到个性化内容推送，我们会<b>i）向您获取及调用您的开机广播权限用于启动问旅或其关联公司移动客户端软件，ii）采用第三方消息推送服务为您生成标识ID用于区分您的设备的唯一性，iii）读取手机状态，用于判断问旅软件处在前台还是后台，以提升推送服务的时效性。</b></p>
      <p>2.8.为向您展示个性化的内容，或确保您能获知更为适宜的内容，我们可能会收集在<b>您在问旅应用软件上搜索/播放/展示推荐内容的记录、音视频播放记录，使用您填写的个人资料信息（如性别、年龄、生日）。</b></p>
      <p>2.9.为向您提供更便捷、更符合您个性化需求的商品信息展示、搜索及推送服务，在使用本应用时，我们会根据您的<b>设备信息（包括2.2条所述设备信息及应用列表信息）</b>和您使用问旅客户端时的<b>浏览使用信息</b>，提取您的偏好特征，并基于特征标签产出间接人群画像，用于向您展示、推送信息和可能的商业广告<b>（请您理解，单独的设备信息无法识别特定自然人的身份信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化处理）</b>。请您知悉并同意，我们在运营过程中可能会通过您在使用我们产品或服务的过程中提供的联系方式（电话、邮箱及其他联系方式）向您发送一种或多种的通知，例如消息告知、身份验证、安全验证、用户使用体验调研；我们也可能会以短信、电话的方式，为您提供您可能感兴趣的服务，功能或活动及相关商业性信息。我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或提供的其他方式进行退订或关闭，也可以直接与我们联系进行退订。</p>
      <p>2.10．在您分享或被分享信息、参加活动情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以为您实现跳转、分享、活动联动功能或服务。</p>
      <p>2.11.设备权限调用汇总</p>
      <p>问旅应用软件在提供服务过程中，会调用您的主要设备权限汇总如下。您可以在设备的设置功能中选择关闭部分或全部权限。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引：</p>

      <table width="100%" border={1}>
        <tr>
          <td><b>设备权限</b></td>
          <td><b>对应业务功能</b></td>
          <td><b>功能场景说明</b></td>
          <td><b>是否可关闭</b></td>
        </tr>
        <tr>
          <td><b>麦克风（录音）</b></td>
          <td>1.内容发布2.直播3.消息和聊天</td>
          <td>1.发布内容时进行录音2. 与其他用户聊天时发送语音消息、发布短视频内容时进行录音</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
        <tr>
          <td><b>通知</b></td>
          <td>系统通知</td>
          <td>APP相关消息推送</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
        <tr>
          <td><b>网络权限</b></td>
          <td>上网功能</td>
          <td>开启上网功能，实时读取与更新</td>
          <td>可以，关闭后APP内容无法实时读取与更新，无法正常使用APP</td>
        </tr>
        <tr>
          <td><b>相机（摄像头）</b></td>
          <td>1.内容笔记2.扫一扫3. 消息4.注册认证</td>
          <td>1.发布内容时进行拍摄2.添加好友或活动相关二维码扫描3. 与其他用户聊天时进行拍摄4.注册和认证时进行拍摄</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
        <tr>
          <td><b>日历</b></td>
          <td>1. 旅行提醒</td>
          <td>提醒行程</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
        <tr>
          <td><b>地理位置</b></td>
          <td>1. 内容推荐2. 内容发布3.. 资料编辑4. 安全运行与风控验证</td>
          <td>1. 根据用户所在位置推荐内容，如附近功能的地图展示功能2. 用户发布内容时定位所在区域，如用户使用含定位的贴纸、发布定位标签、使用标记功能、直播开播定位3. 个人页编辑地区5. 本政策第2.2条描述</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
        <tr>
          <td><b>存储（相册、媒体和其他文件）</b></td>
          <td>1. 注册认证2. 内容发布3. 内容保存4.. 消息5. 分享6. 资料编辑7. 意见反馈8. 扫一扫9.安全运行与风控验证</td>
          <td>1. 注册和认证时上传照片或视频2. 发布内容时读取相册和媒体内容、进行存储3. 日志信息记录、信息缓存4. 直播：上传照片、下载直播内容、观看本地视频5.消息：私信下载、与客服沟通时读取相册内容6. 截图分享7. 编辑资料选择头像图片时读取相册内容8. 用“意见反馈”功能时读取相册内容9. 添加好友或活动相关二维码扫描时读取相册内容10.App安全运行与风控验证</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
        <tr>
          <td><b>电话状态（包括设备信息和运行状态）</b></td>
          <td>1. APP安全运行与风控验证2. 内容推荐3. 展示和其他功能优化</td>
          <td>1. 本政策第2.2条描述2. 本政策第2.15条描述3. 展示和其他功能优化</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
      </table>

      <p><b>3.征得授权同意的例外</b></p>
      <p>根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</p>
      <p>（1）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</p>
      <p>（2）为履行法定职责或者法定义务所必需；</p>
      <p>（3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
      <p>（4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
      <p>（5）依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
      <p>（6）法律法规规定的其他情形。</p>
      <h2>二、我们如何共享、转让、公开披露您的个人信息</h2>
      <p><b>1.共享：</b></p>
      <p>对于您的个人信息，我们不会与任何公司、组织和个人进行共享，除非存在以下一种或多种情形：</p>
      <p><b>（1）事先已得到您的授权；</b></p>
      <p><b>（2）您自行提出的；</b></p>
      <p><b>（3）与商业合作伙伴的必要共享：</b></p>
      <p><b>您理解并知悉，为了向您提供更完善、优质的产品和服务，我们将授权商业合作伙伴为您提供部分服务。此种情形下，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。请您注意，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们将对信息数据的输出形式、流转、使用进行安全评估与处理，以保护数据安全。同时，我们会对合作伙伴进行严格的监督与管理，一旦发现其存在违规处理个人信息的行为，将立即停止合作并追究其法律责任。</b></p>
      <p>目前，我们的合作伙伴包括以下类型</p>
      <p>A.广告和统计分析类合作伙伴：为帮助进行广告或决策建议、提高广告有效触达率、进一步了解用户需求，我们可能会与委托我们进行信息推广和广告投放的合作伙伴、或统计分析类合作伙伴共享去标识化的设备信息或统计信息，这些信息难以或无法与你的真实身份相关联。<b>请您知悉，为了您的信息安全，我们目前仅与已签署严格数据安全保密协议的合作伙伴进行合作。</b></p>
      <p>B.内容审核服务商：为保证用户在互联网信息平台上发布的内容是安全的，我们可能会向委托的第三方内容审核服务商共享您在平台上发布的内容信息。</p>
      <p>C实名认证审核服务商：当您进行实名认证或使用找回账号功能时，我们会向第三方审核服务商共享您的个人信息，以便您顺利找回账号。</p>
      <p>D.第三方小程序：当您使用第三方小程序时，未经您同意，我们不会向这些开发者、运营者共享您的个人信息。当您使用小程序时，小程序可能会使用您授权的相关系统权限，您可以在相应的小程序中撤回该等授权。</p>
      <p>E.数字藏品技术服务提供方：当您使用数字藏品功能，我们会向第三方技术服务商共享您向平台提供的个人信息，以便您顺利体验该功能。</p>
      <p>F.其他业务合作伙伴：委托我们进行推广和广告投放相关的合作伙伴。您授权我们有权与委托我们进行推广和广告投放的合作伙伴共享我们使用您的相关信息集合形成的<b>间接用户画像</b>，以帮助其进行广告或决策建议、提高广告有效触达率、进一步了解用户需求。<b>但我们承诺：未经您的同意，我们不会与其共享可以识别您身份的个人信息。</b></p>
      <p>G.合作的第三方SDK服务商：当您使用问旅APP中由第三方提供的功能时，我们可能会接入由第三方提供的软件开发包（SDK）以实现相关功能。此时，第三方SDK服务商可能会收集您相关的个人信息。<b>我们会尽到审慎义务，对前述第三方SDK进行严格的安全监测，以保护个人信息安全：</b></p>
      <p>a.一键登录功能：为给您提供账号登录——一键登录功能，第三方服务商可能会获取您的必要设备信息、网络相关信息、地理位置、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息;</p>
      <p>b.第三方账号登录功能：为给您提供第三方账号登录的功能，第三方服务商可能会获取您的必要设备信息、网络相关信息、地理位置、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息；</p>
      <p>c.消息推送服务：为给您提供消息推送，第三方推送服务商可能会获取您的推送SDK版本号、必要设备信息、手机状态信息、地理位置信息、网络相关信息、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息以便推送您可能更感兴趣的信息；</p>
      <p>d.地理位置服务：为给您提供地理位置服务（内容推荐、内容发布、购物时编辑收货信息、资料编辑），第三方地理位置服务商会收集您的设备信息及地理位置信息以便可以向您推送附近内容；</p>
      <p>e.云储存服务：为给您提供云端储存服务（您上传至问旅的图片、音视频内容会储存在云端以及有关场景），第三方服务商可能会收集您的设备信息、网络相关信息、运营商信息；</p>
      <p>f.账号安全服务：为保障您和其他用户的账号与财产安全，使您和我们的正当合法权益免受不法侵害，第三方安全服务商可能会收集您的必要设备信息、网络相关信息、运营商信息、日志信息、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息以提供账号安全服务。</p>
      <p>g.支付服务：为保障您顺利购买平台上的商品/服务、虚拟商品，第三方支付服务商可能会收集您的设备信息、网络信息、运营商信息以实现顺利完成支付以及保证支付安全。</p>
      <p>h.直播服务：为保障平台直播功能的顺利使用，第三方服务商可能会收集您的设备信息、网络相关信息、推送SDK版本号、运营商信息。</p>
      <p>（4）当您选择参加我们及我们的关联方或平台上的第三方举办的营销活动时，<b>可能需要您提供姓名、性别、通信地址、联系方式、银行账号信息。这些信息可能涉及敏感个人信息，拒绝提供可能会影响您参加相关活动，但不会影响其他功能。</b>只有经过您的同意，我们才会将这些信息与关联方或第三方共享，以保障您在活动中获得体验一致的服务，或委托第三方及时向您兑现奖励。</p>
      <p>（5）与我们的关联公司必要共享：我们可能会与我们的关联公司共享您的个人信息。我们只会共享必要的个人信息，且这种共享受本政策所声明的目的的约束。关联公司如要改变个人信息的处理目的，将再次征得您的授权和同意；</p>
      <p>（6）您可以基于问旅应用软件与第三人（包括不特定对象）共享您的个人信息或其他信息，但因您的共享行为而导致的信息泄露、被使用及相关情况，与问旅无关，问旅不因此承担法律责任。</p>
      <p><b>2.转让：</b></p>
      <p>转让是指将取得您个人信息的控制权转让给其他公司、组织或个人。除非获取您的明确同意，否则我们不会将您的个人信息转让给任何公司、组织或个人。但下述情形除外：</p>
      <p>（1）事先已征得您的同意；</p>
      <p>（2）您自行提出的；</p>
      <p>（3）如果公司发生合并、分立、解散、被宣告破产，将可能涉及到个人信息转让，此种情况下我们会告知您接收方的名称或者姓名和联系方式并要求新的持有您个人信息的公司、组织继续受本政策的约束。若接收方变更个人信息处理目的、处理方式的，我们将要求其重新获取您的明示同意。</p>
      <p>（4）其他法律法规规定的情形。</p>
      <p><b>3.公开披露：</b></p>
      <p>公开披露是指向社会或不特定人群发布信息的行为。原则上，我们不会对您的个人信息进行公开披露。但下述情况除外：</p>
      <p>（1）因公布账号中奖、处罚通知时展示必要的相关脱敏信息；</p>
      <p>（2）取得您的明示同意后。</p>
      <p><b>4.共享、转让、公开披露个人信息授权同意的例外情形</b></p>
      <p>根据相关法律法规的规定，在以下情形中，我们可能在未事先征得您的授权同意的情况下共享、转让、公开披露您的个人信息：</p>
      <p>（1）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</p>
      <p>（2）为履行法定职责或者法定义务所必需；</p>
      <p>（3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
      <p>（4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
      <p>（5）依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
      <p>（6）法律法规规定的其他情形。</p>
      <p>请您了解，根据现行法律规定及监管要求，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，无需另行向您通知并征得您的同意。</p>
      <h2>三、我们如何存储和保护您的个人信息</h2>
      <p><b>1.存储：</b></p>
      <p>存储地点：我们将从中华人民共和国境内获得的个人信息存放于中华人民共和国境内。以下情形下，我们会确保在履行了法律规定的义务后，向境外实体提供您的个人信息：</p>
      <p>（1）适用的法律有明确规定;</p>
      <p>（2）获得您的明确授权;</p>
      <p>（3）您通过互联网进行跨境交易等个人主动行为。</p>
      <p>针对以上情形，我们会通过合同等形式确保以不低于本政策规定的程度保护您的个人信息。</p>
      <p>存储时间：我们承诺始终按照法律的规定在合理必要期限内在存储您个人信息。超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。</p>
      <p>如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</p>
      <p><b>2.保护：</b></p>
      <p>为了保护您的个人信息安全，我们将努力采取各种符合行业标准的安全措施来保护您的个人信息以最大程度降低您的个人信息被毁损、盗用、泄露、非授权访问、使用、披露和更改的风险。我们将积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。</p>
      <p>您的账户均有安全保护功能，请妥善保管您的账户及密码信息。问旅将通过向其它服务器备份、对用户密码进行加密及相关安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。为防止安全事故的发生，我们已按照法律法规的规定，制定了妥善的预警机制和应急预案。如确发生安全事件，我们将及时将相关情况选择以邮件、信函、电话、推送通知及相关方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。</p>
      <p>当我们的产品或服务发生停止运营的情形时，我们会及时停止继续收集个人信息的活动。上述变更，我们将以推送通知、公告及相关形式通知你，并在合理的期限内删除你的个人信息或进行匿名化处理（所谓“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。）</p>
      <h2>四、有关第三方提供产品和/或服务的特别说明</h2>
      <p>问旅中可能包括第三方产品和/或服务或链接至第三方提供的信息和/或服务，<b>该第三方产品和/或服务在使用前，您需要跳转到相应的小程序或第三方页面。您使用该第三方服务（包括您向该第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非本政策）约束，您需要仔细阅读其条款并自行决定是否接受。</b>请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。本政策仅适用于我们所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，<b>第三方使用您信息时的行为由其自行负责。</b></p>
      <h2>五、我们如何使用Cookie和其他同类技术</h2>
      <p><b>1.Cookies的使用</b></p>
      <p>（1）在您接受cookies的情况下，问旅会在您的相关移动设备上设定或取用cookies，以便您能登录或使用依赖于cookies的问旅应用软件服务或功能。问旅使用cookies可为您提供更加周到的个性化服务，包括推广服务。</p>
      <p>（2）您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式或在移动设备中设置拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的问旅应用软件服务或功能。</p>
      <p>（3）通过问旅所设cookies所取得的有关信息，将适用本政策。</p>
      <h2>六、未成年人保护</h2>
      <p><b>以下条款请未成年用户（特别是未满十四周岁的儿童用户）在监护人的陪同下仔细阅读，并由监护人在充分理解后作出是否接受或拒绝本政策的决定：</b></p>
      <p><b>1.若用户是未满18周岁的未成年人（特别是未满十四周岁的儿童用户），应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用问旅相关服务。</b></p>
      <p><b>2.我们重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用问旅相关服务。</b></p>
      <p><b>3.我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您根据本政策第十条提供的联系方式及时与我们联系。</b></p>
      <h2>七、我们如何更新隐私政策</h2>
      <p>1.为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求更新本政策的条款，这些更新将构成本政策的一部分。未经您明确同意，我们不会削减您依据当前生效的本政策所应享受的权利。</p>
      <p>2.如遇本政策更新，我们会通过APP客户端推送通知或其他合理方式通知您，以便您能及时了解本政策的最新版本。</p>
      <h2>八、如何联系我们</h2>
      <p>如您对个人信息保护问题有任何投诉、建议、疑问，或您对本政策有任何疑问，您可以通过以下方式联系我们，我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内予以答复：hr@haolvheshan.cn</p>
      <h2>九、其他</h2>
      <p><b>1.因本政策以及我们处理您个人信息事宜引起的任何争议，您可诉至广州市天河区法院。</b></p>
      <p><b>2.如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。</b></p>
      <p></p>
    </div>
  );
}

export default PrivacyPolicy;