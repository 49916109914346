import React from "react";

interface ToastProps {
  message: string;
  visible: boolean;
}

const CustomToast: React.FC<ToastProps> = ({ message, visible }) => {
  if (!visible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "10%",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#333",
        color: "#fff",
        padding: "10px 20px",
        borderRadius: "8px",
        zIndex: 1000,
        opacity: visible ? 1 : 0,
        transition: "opacity 0.5s ease",
      }}
    >
      {message}
    </div>
  );
};

export default CustomToast;