import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './App';
import Share from './h5/Share';
import { API_URL,API_URL_TEST, TIMEOUT, ERROR_MESSAGES } from './h5/constants';

declare const wx: any;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

init();

async function init() {
  try {
    await loadWeChatSDK();

    fetchData().then((response) => {
      console.log(response.data.appId); // 可以访问Data中的属性
      console.log(response.extraField); // 可以访问Response中的额外属性
          // 在 SDK 加载完成后可以执行任何需要的配置
    wx.config({
      debug: false,
      appId: response.data.appId,
      timestamp: response.data.timestamp,
      nonceStr: response.data.nonceStr,
      signature: response.data.signature,
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData'
      ],
      openTagList: ['wx-open-launch-app'],
    });

        // 'onMenuShareAppMessage',//分享接口
        // 'onMenuShareTimeline',
        // 'getlocation',//获取位置
        // 'openLocation',//打开位置
        // 'scanQRCode',//扫一扫接口
        // 'chooseWXPay',//微信支付
        // 'chooseImage',//拍照或从手机相册中选图接口
        // 'previewImage',//预览图片接口
        // 'uploadImage'//上传图片

    wx.ready(() => {
      console.log('WeChat JS SDK is ready');
    });

    wx.error((err: any) => {
      console.error('WeChat JS SDK Error:', err);
    });
    });

  } catch (error) {
    console.error('Error during initialization:', error);
  }
}

function loadWeChatSDK() {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'; // 或者使用本地路径
    script.onload = () => {
      console.log('WeChat SDK loaded successfully');
      resolve();
    };
    script.onerror = () => {
      console.error('Failed to load WeChat SDK');
      reject();
    };
    document.head.appendChild(script);
  });
}

export interface Response {
  code: number;
  data: Data;
  message: string;
  timestamp: number;
  [property: string]: any;
}

export interface Data {
  /**
   * 公众号的唯一标识
   */
  appId: string;
  /**
   * 生成签名的随机串
   */
  nonceStr: string;
  /**
   * 签名
   */
  signature: string;
  /**
   * 生成签名的时间戳
   */
  timestamp: string;
  [property: string]: any;
}


async function fetchData(): Promise<Response> {
 const domainUrl = process.env.NODE_ENV === 'production' ? API_URL : API_URL_TEST; 
 console.log('999' + domainUrl)
 const response = await fetch(domainUrl + '/main/v1/weixin/jsapi/sign', {
    method: 'POST', // 指定请求方法为POST
    mode: 'cors',    // 允许跨域请求
    headers: {
        'Content-Type': 'application/json', // 设置请求头为JSON
        //'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJlYmNkMzlmNjJmMzM0NTVkOTcyNjQ1YmNjZjJhNDE1OSIsInVpZCI6IjUwMDEwIiwicGhvbmUiOiIxODMxNzg4MTM5MSIsIm5pY2tuYW1lIjoi5pi156ewIiwic3ViIjoiMTgzMTc4ODEzOTEifQ.mIr3y-vtfdSVKdjQVK8XA92-DuBZYP__n72UEjGq_9mrqVyE3LA3EvQAYF-UJJXsFpwuzPhvTtFwsZUnZq55dg' // 添加Authorization头部
    },
});
  const jsonData = await response.json();
  console.log(jsonData); 

  // 使用类型断言将返回的 JSON 数据转换为 Response 接口类型
  const result: Response = {
    code: jsonData.code,
    data: {
      appId: jsonData.data.appId,
      nonceStr: jsonData.data.nonceStr,
      signature: jsonData.data.signature,
      timestamp: jsonData.data.timestamp,
      ...jsonData.data // 处理Data接口中的可选属性
    },
    message: jsonData.message,
    timestamp: jsonData.timestamp,
    ...jsonData // 处理Response接口中的可选属性
  };

  return result;
}