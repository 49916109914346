import React, { useEffect } from 'react';

const Sample: React.FC = () => {
  const appStoreLink = 'https://apps.apple.com/app/id6502935540'; // App Store链接

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = appStoreLink;
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>正在打开 App...</h1>
      <meta http-equiv="refresh" content={`0;url=${appStoreLink}`} />
    </div>
  );
};

export default Sample;
