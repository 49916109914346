import React, { useState,useEffect } from "react";
import CustomToast from "./CustomToast"; // 引入自定义Toast组件
import backgroundImage from '../assets/images/hlhs_app_share_content_background.png'; // 替换为你的图片路径
import btnBackgroundImage from '../assets/images/hlhs_app_share_btn_background.png'
import CallApp from 'callapp-lib';

declare global {
  interface Window {
    __wxjs_environment?: string;
  }
}

const Share: React.FC = () => {

  useEffect(() => {
    document.title = "问旅";
  }, []);

  const universalLink = "https://haolvheshan.cn/app/sample"; // Universal Link
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleClick = () => {
    console.log("Button clicked!"); // 显示点击按钮的消息

    let clickedAt = +new Date();
    console.log(`Current timestamp: ${clickedAt}`); // 显示当前时间戳

    if (isWeixin()) {
      console.log("WeChat browser detected, using wx-open-launch-app");
      const btn = document.getElementById("launch-btn") as HTMLElement;

      btn?.addEventListener("launch", () => {
        console.log("Launch success");
      });

      btn?.addEventListener("error", (e: Event) => {
        const customEvent = e as CustomEvent;
        console.log(`Launch failed: ${customEvent.detail}`);
      });

    } else {
      console.log(`Not WeChat browser, using universal link: ${universalLink}`);
      const urlScheme = "hlhs://";  // URL scheme for the app
      // 配置要跳转的 App 信息
      const options = {
        scheme: {
          protocol: urlScheme,  // 微信应用的协议头
        },
        universal: {
          host:'haolvheshan.cn'
        },
        intent: {
          scheme: urlScheme,    // 添加 scheme 属性
          package: 'com.tencent.mm',  // 安卓应用的包名
        },
        appstore: 'https://apps.apple.com/app/id6502935540',  // 应用商店地址
        fallback: universalLink,  // 当用户没有安装应用时跳转的网页
        isSupportWeibo: true
      };

      // 实例化 CallApp 对象
      const callApp = new CallApp(options);

      // 调用 App
      callApp.open({
        path: 'sharePage',  // 跳转的具体页面
      });

      // // Attempt to open via URL scheme
      // window.location.href = urlScheme;
    
      // // If the URL scheme fails after 1.5 seconds, redirect to the fallback link
      // setTimeout(() => {
      //   console.log("URL scheme failed, redirecting to fallback link...");
      //   window.location.href = universalLink;
      // }, 1500);
    }
  };

  function isWeixin(): boolean {
    const ua = window.navigator.userAgent.toLowerCase();
    const isMicroMessenger = /micromessenger/i.test(ua);
    const isWeChatEnv = window.__wxjs_environment === 'miniprogram';
  
    return isMicroMessenger || isWeChatEnv;
  }

  // 显示Toast的函数
  const showToast = (message: string) => {
    console.log(message)
    // setToastMessage(message);
    // setToastVisible(true);
    // setTimeout(() => setToastVisible(false), 3000); // 3秒后隐藏
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        display: "grid",
        justifyContent: "space-evenly",
      }}
    >
      <CustomToast message={toastMessage} visible={toastVisible} />
      {/* <h1>{isWeixin() ? "微信分享页" : "默认分享页"}</h1> */}
      {isWeixin() ? (
        // @ts-ignore
<div style={{ backgroundColor: 'transparent',padding: '10px',borderRadius: '8px',backgroundImage: `url(${btnBackgroundImage})`,backgroundSize: 'cover',backgroundPosition: 'center',width: '219px',height: '54px',position: 'fixed', bottom: '35pt', left: '50%',transform: 'translateX(-50%)'}}><wx-open-launch-app id="launch-btn" appid="wx358ae368ef31f07c" extinfo="your-extinfo" style={{width: '100%',height: '100%',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><script type="text/wxtag-template"><style>{`.btn { padding: 12px; width: 100%;  height: 100%; background-color: transparent; border: none;  }`}</style><button className="btn" onClick={handleClick}></button></script></wx-open-launch-app></div>      ) : (
        <button onClick={handleClick}
        style={{
          width: '219px',
          height: '54px',
          backgroundColor: 'transparent',
          backgroundImage: `url(${btnBackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          border: 'none',
          cursor: 'pointer',
          position: 'fixed',   // 使用fixed保证按钮在屏幕内固定
          bottom: '35pt',      // 距离屏幕底部65pt
          left: '50%',         // 按钮居中
          transform: 'translateX(-50%)',
        }}
        ></button>
      )}
    </div>
  );
};

export default Share;