import React, { useState } from 'react';
import productName from '../assets/images/icon_name.png';
import slogan from '../assets/images/text_slogan.png';
import aboutUs from '../assets/images/text_about_us.png';
import contact from '../assets/images/text_contact.png';
import indexFire from '../assets/images/icon_index_fire.png';
import aboutUsFire from '../assets/images/icon_about_us_fire.png';
import '../assets/styles/App.css';

const Home: React.FC = () => {
  const [menuItem, setMenuItem] = useState<string>('首页');

  return (
    <div className="App">
      <header className="App-Container">
        <header className="App-Header">
          <img src={productName} alt="Product Name" />
          <div className="App-Header-Menu">
            {['首页', '关于我们'].map((item) => (
              <div
                key={item}
                className="App-Menu-Item"
                onClick={() => setMenuItem(item)}
              >
                <div
                  className={
                    menuItem === item
                      ? 'App-Menu-Item-Highlight'
                      : 'App-Menu-Item-Normal'
                  }
                >
                  {item}
                </div>
                <div
                  className={
                    menuItem === item
                      ? 'App-Menu-Slider-Highlight'
                      : 'App-Menu-Slider-Normal'
                  }
                />
              </div>
            ))}
          </div>
        </header>
        <div className="App-Content">
          <img
            className="App-Content-Fire"
            src={menuItem === '首页' ? indexFire : aboutUsFire}
            alt="Fire"
          />
          <div className="App-Content-Text">
            {menuItem === '首页' ? (
              <img src={slogan} alt="Slogan" />
            ) : (
              <>
                <img src={aboutUs} alt="About Us" />
                <div style={{ height: 80 }} />
                <img src={contact} alt="Contact" />
              </>
            )}
          </div>
        </div>
        <div />
        <div className="App-Container-Provider">粤ICP备2024268786号-1</div>
      </header>
    </div>
  );
};

export default Home;
