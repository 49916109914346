// constants.ts

export const API_URL_DEV = "https://dev-api.haolvheshan.cn:30011";
export const API_URL_TEST = "https://dev-api.haolvheshan.cn:30011";
export const API_URL = "https://api.haolvheshan.cn";
export const TIMEOUT = 5000;
export const MAX_RETRIES = 3;

export const ERROR_MESSAGES = {
    NETWORK_ERROR: "Network error, please try again later.",
    AUTH_ERROR: "Authentication failed, please check your credentials."
};

export const STATUS_CODES = {
    OK: 200,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
};